import {renderRichText, storyblokEditable} from '@storyblok/react'
import React from 'react'
import {HighlightContainerStoryblok} from 'types/storyblok-types'
import {TextBlock} from './Text'
import BackgroundMapper from 'util/BackgroundMapper'

type highlightContainerPropsType = {
  blok: HighlightContainerStoryblok
}

type HighlightBlockPropsType = {
  title: HighlightContainerStoryblok['title']
  text: string
  blok: HighlightContainerStoryblok
  backgroundColor: HighlightContainerStoryblok['background_color'][0]['background_color']
}

export function HighlightBlock({
  title,
  text,
  blok,
  backgroundColor,
}: HighlightBlockPropsType) {
  return (
    <div
      className={`overflow-hidden rounded-dats ${BackgroundMapper(
        backgroundColor,
      )} w-full p-[30px]`}
      {...storyblokEditable(blok)}
    >
      <span className="text mb-5 block font-rubik font-semibold">{title}</span>
      <TextBlock innerHtml={text} />
    </div>
  )
}

const HighlightContainer = ({blok}: highlightContainerPropsType) => {
  return (
    <HighlightBlock
      title={blok.title}
      text={renderRichText(blok.text)}
      backgroundColor={
        blok.background_color.length > 0
          ? blok.background_color[0]['background_color']
          : 'white'
      }
      blok={blok}
    />
  )
}

export default HighlightContainer
